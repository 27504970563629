
import barba from '@barba/core';
import { navbar } from "../modules/navbar/navbar";
// import MultiMenu from 'multi-menu' // when you use webpack/rollup
// import 'multi-menu/css/multi-menu.css' // when you use webpack/rollup
import utils from 'fizzy-ui-utils';
import LazyLoad from "vanilla-lazyload";
// import { Loader } from "@googlemaps/js-api-loader";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
// import {thomDropdown} from "../modules/dropdown/dropdown";
import Scrollbar from 'smooth-scrollbar';
import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import lightGallery from 'lightgallery';
// import lgMediumZoom from 'lightgallery/plugins/mediumZoom';
import lgVideo from 'lightgallery/plugins/video';
import Pristine from 'pristinejs';
import {preloader} from './components/preloader';

// import Swiper JS
import Swiper from 'swiper';
import { EffectFade, Autoplay, Mousewheel, Keyboard } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/swiper.css';
import 'swiper/modules/effect-fade.css';
import 'swiper/modules/effect-fade-element.css';
import 'swiper/modules/mousewheel.css';

import { getDomIndex, isTouchDevice } from './components/utils';
import { formReset } from './components/form';

const imagesLoaded = require('imagesloaded');

// fade in and slide up content 
// on Intersection Observer with ScrollTrigger
const animFadeIn = (animFadeInEl) => {
    animFadeInEl.forEach((el, i) => {
        // Set up your animation
        let animFadeInAnim = gsap.to(el, {duration: 1.5, autoAlpha: 1, y: 0, paused: true, delay: .3});
        // Use callbacks to control the state of the animation
        ScrollTrigger.create({
          trigger: el,
          start: "top 90%",
          end: "bottom 90%",
          once: true,
          // markers: true,
          onEnter: self => {
            // If it's scrolled past, set the state
            // If it's scrolled to, play it
            self.progress === 1 ? animFadeInAnim.progress(1) : animFadeInAnim.play()
          }
        });
    });
}

// fade in
const fadeIn = (el, smooth = true, displayStyle = 'block') => {
    el.style.opacity = 0;
    el.style.display = displayStyle;
    if (smooth) {
        let opacity = 0;
        let request;

        let animation = () => {
            el.style.opacity = opacity += 0.04;
            if (opacity >= 1) {
                opacity = 1;
                cancelAnimationFrame(request);
            }
        };

        let rAf = () => {
            request = requestAnimationFrame(rAf);
            animation();
        };
        rAf();

    } else {
        el.style.opacity = 1;
        el.style.display = displayStyle;
    }
};
// fade out
const fadeOut = (el, smooth = true, displayStyle = 'none') => {
    if (smooth) {
        let opacity = el.style.opacity;
        let request;

        let animation = () => {
            el.style.opacity = opacity -= 0.04;
            if (opacity <= 0) {
                opacity = 0;
                el.style.display = displayStyle;
                cancelAnimationFrame(request);
            }
        };

        let rAf = () => {
                request = requestAnimationFrame(rAf);
                animation();
        };
        rAf();

    } else {
        el.style.opacity = 0;
        el.style.display = displayStyle;
    }
};


// Google map loader
// const loader = new Loader({
//     apiKey: _gmapkey,
//     version: "weekly",
//     libraries: ["places"]
// });

// Form Response Action
function MyThomFormResponseAction(title, text) {
    var mytitle = '';

    LoadingShow(document.querySelector('.wrapper'));

    if (title !== undefined && title !== null && title !== "") {
      mytitle = "<div class='title md'><strong>" + title + "</strong></div>";
    } else {
      mytitle = "";
    }  
    var myHtml =
        mytitle +
        "<p class='md'>" + text + "</p>";
      
    document.querySelector("#alert-dialog .dialog-text").innerHTML = myHtml;

    var dialogAlert = new A11yDialog(document.getElementById('alert-dialog'));

    dialogAlert
        .on('show', () => {
            disableBodyScroll(dialogAlert);
            LoadingHide(document.querySelector('.wrapper'));
        })
        .on('hide', () => enableBodyScroll(dialogAlert))

    dialogAlert.show();
}

// Pristine validation configuration
const defaultPristineConfig = {
    // class of the parent element where the error/success class is added
    classTo: 'form-group',
    errorClass: 'has-danger',
    successClass: 'has-success',
    // class of the parent element where error text element is appended
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'text-help' 
};

function timelineFillingUpdate(t) {
    var fillingLine = document.querySelector('.filling-line');
    var left = t.offsetLeft;
    var width = t.offsetWidth;
    var inputs = document.querySelectorAll('.timeline .input');
  
    left = Number(left) + (Number(width)/2);
  
    if(getDomIndex(t) === inputs.length - 1){
      left = "100%"
    }
  
    gsap.to(fillingLine, {
      width: left
    });
}
  
function timelineAuto(inputs) {
    var s = inputs.length;
    var currentInput = document.querySelector('.timeline .input.active');
    var ind = getDomIndex(currentInput) + 1;
    if(ind >= s){
        ind = 0;
    }

    var next = inputs[ind];

    timelineFillingUpdate(next);
    next.classList.add('active');
    document.querySelector('.timeline--description').innerHTML = next.querySelector('.desc').innerHTML;

    [...inputs].forEach(input => {
        var index = getDomIndex(input);
        if(index !== ind){
            input.classList.remove('active')
        }
    })
}

let timelineInterval;

let LoadingShow = (element) => {
    document.body.classList.add('loading');
        
    let loader = document.createElement('div');
    loader.className = 'loader';
    
    let spinner = document.createElement('span');
    spinner.className = 'spinner';
    
    loader.appendChild(spinner);
    element.appendChild(loader);

    element.querySelector('.loader').classList.add('show');
}

let LoadingHide = (element) => {
    element.querySelector('.loader').classList.remove('show');
    setTimeout(() => {
        element.querySelector('.loader').remove();
    }, "500");
    document.body.classList.remove('loading');
}

const App = (container, data) => {

    let myLazyLoad = new LazyLoad({
        container: container,
        unobserve_entered: true,
        elements_selector: ".lazy",
        callback_loaded: function(el){
            // locoScroll.update();
        }
    });

    // Fade in animation
    if(container.querySelector('.fadeIn')){
        const animFadeInEl = gsap.utils.toArray('.fadeIn');
        // console.log(animFadeInEl)
        // Set things up
        gsap.set(animFadeInEl, {autoAlpha: 0, y: 20});
        animFadeIn(animFadeInEl);
    }

    // Numbers
    container.querySelectorAll('.number').forEach((el, i) => {
        // Use callbacks to control the state of the animation
        ScrollTrigger.create({
          trigger: el,
          start: "top 80%",
          once: true,
        //   markers: true,
          onEnter: self => {
            let counts = setInterval(updated, 60);
            let upto = el.querySelector(".value").innerHTML;
            function updated() {
                let count = el.querySelector(".value");
                let maxCount = Number(el.getAttribute("data-val"));
                count.innerHTML = ++upto;
                if (upto === maxCount) {
                    clearInterval(counts);
                }
            }
          }
        });
    });

    // Card Carousel
    if(container.querySelector('.card-carousel')){
        var carousels = container.querySelectorAll('.card-carousel');
        [...carousels].forEach(carousel => {

            ScrollTrigger.create({
                trigger: carousel,
                start: "top 80%",
                once: true,
                // markers: true,
                onEnter: self => {
                    gsap.to(carousel.querySelectorAll('.item'), {
                        opacity: 1,
                        duration: 0.8,
                        stagger: 0.35
                    });
                }
            });

            let swiper = new Swiper(carousel, {
                modules: [ Mousewheel, Keyboard ],
                spaceBetween: 25,
                slidesPerView: 'auto',
                grabCursor: true,
                slidesOffsetAfter: 75,
                keyboard: {
                    enabled: true
                },
                mousewheel: {
                    enabled: true,
                    forceToAxis: true,
                    sensitivity: 5
                },
                breakpoints: {
                    1200: {
                        grabCursor: false,
                        slidesOffsetAfter: 0
                    }
                }
            })
        })
    }

    // Images Carousel
    if(container.querySelector('.images-carousel')){
        var imagesCarousels = container.querySelectorAll('.images-carousel');
        [...imagesCarousels].forEach(carousel => {
            let grabCursor = true;

            if (!isTouchDevice()) {
                grabCursor= false;
            }

            let ImagesSwiper = new Swiper(carousel, {
                modules: [ Mousewheel, Keyboard ],
                slidesPerView: "auto",
                centeredSlides: true,
                spaceBetween: 25,
                loop: true,
                grabCursor: grabCursor,
                lazy: true,
                initialSlide: 2,
                keyboard: {
                    enabled: true
                },
                mousewheel: {
                    enabled: true,
                    forceToAxis: true,
                    sensitivity: 5
                },
            });

            if (!isTouchDevice()) {
                let cursor = carousel.querySelector(".cursor");
                carousel.addEventListener('pointermove', (e) => {
                    let bounds = carousel.getBoundingClientRect();
                    let x = e.clientX - bounds.left;
                    let y = e.clientY - bounds.top;
                
                    gsap.to(cursor, {
                        x: x - cursor.clientWidth / 2,
                        y: y - cursor.clientHeight / 2,
                        duration: 0.5,
                        ease: 'power4'
                    });
                });
            }
        })
    }

    // Timeline
    if(container.querySelector('.timeline')){
        var inputs = container.querySelectorAll('.timeline .input');
        var mobileDesc = container.querySelector('.timeline--description');
        var currentInput = container.querySelector('.timeline .input.active');
        var interval = container.querySelector('.timeline').getAttribute('data-interval')
        mobileDesc.innerHTML = currentInput.querySelector('.desc').innerHTML;

        timelineFillingUpdate(currentInput);

        timelineInterval = setInterval(function(){
            timelineAuto(inputs)
        }, interval);
        
        [...inputs].forEach(input => {
            var ind = getDomIndex(input);
            var dot = input.querySelector('.dot');
            var desc = input.querySelector('.desc').innerHTML;

            dot.addEventListener('click', function(){
                timelineFillingUpdate(input);

                input.classList.add('active');
                mobileDesc.innerHTML = desc;
                
                [...inputs].forEach(input => {
                    var index = getDomIndex(input);
                    if(index !== ind){
                        input.classList.remove('active')
                    }
                })

                // paras.forEach(para => {
                //     var index = getDomIndex(para);
                //     if(index !== ind){
                //         para.classList.remove('active')
                //     } else {
                //         para.classList.add('active')
                //     }
                // })

                clearInterval(timelineInterval);

                timelineInterval = setInterval(function(){
                    timelineAuto(inputs)
                }, interval);
            })
        });

    }

    // video media / Scrolltrigger
    if(container.querySelector('.media')){
        container.querySelectorAll('.media').forEach((el, i) => {
            if(el.querySelector(".video--item")){
                let videoElement = el.querySelector(".video--item");
                let videoSrc = el.querySelector(".video--item").getAttribute('data-src');
                videoElement.src = videoSrc;
                videoElement.load();

                if(el.querySelector('.video--controls')){
                    var controls = el.querySelector('.video--controls');
                    var restart = controls.querySelector('.skip-start');
                    var volume = controls.querySelector('.volume');
                    var state = controls.querySelector('.state');
                    var progress = controls.querySelector('.progress-bar');
            
                    if(window.matchMedia("(min-width: 920px)").matches){
                        setTimeout(function(){
                            fadeOut(controls)
                        }, 2000);
            
                        el.addEventListener('mouseenter', function(){
                            fadeIn(controls, true, 'flex')
                        })

                        el.addEventListener('mouseleave', function(){
                            fadeOut(controls, true)
                        })
                    }
            
                    restart.addEventListener('click', function(){
                        videoElement.pause();
                        videoElement.currentTime = '0';
                        videoElement.play();
                        state.classList.remove('stop');
                    });
        
                    volume.addEventListener('click', function(){
                        volume.classList.toggle("on");
                        if(volume.classList.contains('on')){
                            videoElement.muted = false;
                        } else {
                            videoElement.muted = true;
                        }
                    });
        
                    state.addEventListener('click', function(){
                        state.classList.toggle("stop");
                        if(state.classList.contains('stop')){
                            videoElement.pause();
                        } else {
                            videoElement.play();
                        }
                    });
                }
                
                let itemOnEnter = () => {
                    el.classList.add('is-playing');
                    videoElement.play();
                    if(state){
                        state.classList.remove('stop');
                    }
                }
                let itemOnLeave = () => {
                    el.classList.remove('is-playing');
                    videoElement.pause();
                }

                var mediaTl = gsap.timeline({
                    scrollTrigger: {
                        trigger: el,
                        start: "top 80%",
                        end: "bottom 10%",
                        // markers: true,
                        onEnter: itemOnEnter,
                        onEnterBack: itemOnEnter,
                        onLeave: itemOnLeave,
                        onLeaveBack: itemOnLeave,
                    }
                });
            }
        })
    }

    // Settori modal
    let dialogSectorsEl = container.querySelector('#sectors-dialog');
    
    if(dialogSectorsEl){
        let dialogSectors = new A11yDialog(dialogSectorsEl);
        let modalScroller = dialogSectorsEl.querySelector('.dialog-wrap');

        if(window.matchMedia("(min-width: 640px)").matches){
            modalScroller = dialogSectorsEl.querySelector('.dialog-wrap .text--wrap');
        }
        

        // Resize
        let doit;
        window.onresize = function(){
            clearTimeout(doit);
            if(window.getComputedStyle(dialogSectorsEl, null).display != 'none'){
                doit = setTimeout(function(){
                    Scrollbar.destroy(modalScroller);
                    if(window.matchMedia("(min-width: 640px)").matches){
                        modalScroller = dialogSectorsEl.querySelector('.dialog-wrap .text--wrap');
                    } else {
                        modalScroller = dialogSectorsEl.querySelector('.dialog-wrap');
                    }

                    sectorsModalScrollbar();
                }, 100);
            }
        };

        // Make scrollbar
        let sectorsModalScrollbar = () => {
            Scrollbar.init(modalScroller, {
                // delegateTo: dialogSectorsEl,
                alwaysShowTracks: false
            });
        }
        // Dialog callback
        dialogSectors
            .on('show', () => {
                sectorsModalScrollbar();
                if(window.matchMedia("(min-width: 640px)").matches){
                    disableBodyScroll(dialogSectorsEl);
                }
            })
            .on('hide', () => {
                if(window.matchMedia("(min-width: 640px)").matches){
                    enableBodyScroll(dialogSectorsEl);
                }
                dialogSectorsEl.querySelector('.dialog-wrap .image img').src = "";
                dialogSectorsEl.querySelector('.dialog-wrap .text--wrap').innerHTML = "";
                Scrollbar.destroy(modalScroller);
            })
        
        var sectorsModalButtons = container.querySelectorAll(".sectors--item");
        sectorsModalButtons.forEach((btn) => {
            btn.onclick = (event) => {
                event.preventDefault();

                LoadingShow(btn);

                let image = btn.querySelector('.modal .image').getAttribute('data-img'); 
                let text = btn.querySelector('.modal .text').innerHTML;
                
                dialogSectorsEl.querySelector('.dialog-wrap .image').dataset.img = image;
                dialogSectorsEl.querySelector('.dialog-wrap .text--wrap').innerHTML = text;

                preloader('#sectors-dialog .image').then(() => {
                    dialogSectorsEl.querySelector('.dialog-wrap .image img').src = dialogSectorsEl.querySelector('.dialog-wrap .image').dataset.img;
                    dialogSectors.show();
                    LoadingHide(btn);
                })          
            }
        })
    }

    // thomDropdown(container);

    // Privacy Dialog
    var dialogPrivacyEl = document.getElementById('privacy-dialog');
    var dialogPrivacy = new A11yDialog(dialogPrivacyEl);
    var dialogPrivacyScroller = dialogPrivacyEl.querySelector('.dialog-text');

    dialogPrivacy
        .on('show', () => {
            disableBodyScroll(dialogPrivacyEl);
            Scrollbar.init(dialogPrivacyScroller, {
                alwaysShowTracks: false,
                delegateTo: dialogPrivacyEl
            });
        })
        .on('hide', () => {
            dialogPrivacyEl.querySelector('.dialog-text').innerHTML = "";
            Scrollbar.destroy(dialogPrivacyScroller);
            enableBodyScroll(dialogPrivacyEl);
        })
    
    var ajaxModalButtons = document.querySelectorAll("[rel='ajax:modal']");
    ajaxModalButtons.forEach((btn) => {
        btn.onclick = (event) => {
            event.preventDefault();
            LoadingShow(document.querySelector('.wrapper'));
            var page = btn.getAttribute('href');

            let xhttp = new XMLHttpRequest();
            
            xhttp.onload = function() {
                var resp = this.responseText;  
                var parser = new DOMParser();
                var xmlDoc = parser.parseFromString(resp, "text/html");
                var tds = xmlDoc.querySelector(".privacy--text");

                dialogPrivacyEl.querySelector('.dialog-text').innerHTML = tds.innerHTML;

                dialogPrivacy.show();

                LoadingHide(document.querySelector('.wrapper'));
            }

            xhttp.open("GET", page);
            xhttp.send();
        }
    })

    // Dialog Gallery single image/video
    document.querySelectorAll('.lg-btn').forEach((btn)  => {
        lightGallery(btn, {
            licenseKey: 'EB342F23-EEA8-48C2-B726-0CFCE5A4AECF',
            plugins: [lgVideo],
            mode: 'lg-fade',
            zoom: false,
            selector: "this",
            counter: false,
            download: false,
            getCaptionFromTitleOrAlt: false,
            zoomFromOrigin: false,
            hideBarsDelay: 3000,
            vimeoPlayerParams: {
                byline : 0,
                portrait : 0,
                dnt: true
            },
            youTubePlayerParams: {
                modestbranding : 1,
                showinfo : 0,
                controls : 0,
                rel: 0
            }
        })
    })

    // Dialog gallery multiple images
    if(container.querySelector('.open-gallery')){
        let galleryButtons = container.querySelectorAll('.open-gallery');

        galleryButtons.forEach((btn)  => {
            let gallery = document.getElementById(btn.getAttribute('data-gallery'));

            let imagesCarousels = container.querySelectorAll('.images-carousel');
            if(imagesCarousels){
                gallery.addEventListener('lgBeforeOpen', () => {
                    [...imagesCarousels].forEach((slider) => {
                        slider.swiper.keyboard.disable()
                    })
                });

                gallery.addEventListener('lgBeforeClose', () => {
                    [...imagesCarousels].forEach((slider) => {
                        slider.swiper.keyboard.enable()
                    })
                });
            }

            lightGallery(gallery, {
                mode: 'lg-fade',
                plugins: [lgVideo],
                licenseKey: 'EB342F23-EEA8-48C2-B726-0CFCE5A4AECF',      
                selector: "img",
                counter: false,
                download: false,
                getCaptionFromTitleOrAlt: false,
                mobileSettings: {
                    showCloseIcon: true
                },
                vimeoPlayerParams: {
                  byline : 0,
                  portrait : 0,
                  dnt: true
                },
                youTubePlayerParams: {
                  modestbranding : 1,
                  showinfo : 0,
                  controls : 0,
                  rel: 0
                }
            });

            btn.addEventListener('click', function(e) {    
                e.preventDefault();            
                gallery.querySelector("img").click();
            });
        });
    }
    
    let forms = container.querySelectorAll('.thom-form');
    document.querySelector('.thom-form:not(#newsletterForm)') ? document.querySelector(".grecaptcha-badge").classList.remove('hide') : document.querySelector(".grecaptcha-badge").classList.add('hide');
    if(forms){
        forms.forEach((form) => {
            let validateForm = new Pristine(form, defaultPristineConfig);

            form.addEventListener('submit', function (e) {
                e.preventDefault();

                LoadingShow(document.querySelector('.wrapper'));

                let valid = validateForm.validate(); // returns true or false

                if(form.querySelector(".GoogleCaptchaToken")){
                    var rSiteKey = form.querySelector('button[type=submit]').dataset.sitekey;
                    var rAction = form.querySelector('button[type=submit]').dataset.action;
                    // console.log(rSiteKey)
                    grecaptcha.ready(function() {
                        // console.log('grecaptcha.ready')
                        grecaptcha.execute(rSiteKey, {action: rAction}).then(function(token) {
                            // Add your logic to submit to your backend server here.
                            // Response Form
                            // console.log(token)
                            form.querySelector(".GoogleCaptchaToken").value = token;
                            FormSubmit(valid);
                        });
                    });
                } else {
                    FormSubmit(valid);
                }

                let FormSubmit = (valid) => {
                    if(valid){
                        let formData = new FormData(form);
                        // console.log(Array.from(formData));        
                        fetch(form.getAttribute('action'), {
                            method: form.getAttribute('method'),
                            body: formData
                        }).then(response => {
                            // The API call was successful!
                            if (response.ok) {
                                return response.json();
                            } else {
                                return Promise.reject(response);
                            }
                        }).then(function (data) {
                            // console.log(data)
                            if(data.Status){
                                if(data.Url !== undefined && data.Url !== null && data.Url !== ""){
                                    window.location.href = data.Url
                                } else {
                                    if(data.Message){
                                        MyThomFormResponseAction(data.Title, data.Message);
                                    }
                                }
                                LoadingHide(document.querySelector('.wrapper'));
                                formReset(form);
                            } else {
                                if(data.Message){
                                    MyThomFormResponseAction(data.Title, data.Message);
                                }
                                LoadingHide(document.querySelector('.wrapper'));
                            }
            
                            return true;
                        }).catch(function (err) {
                            // There was an error
                            console.warn('Something went wrong.', err);
                            form.querySelector('.thom-form__error').classList.remove('visuallyhidden');
                            form.classList.remove('loading');
                        });
                    } else {
                        form.querySelector('.thom-form__error').classList.remove('visuallyhidden');
                        LoadingHide(document.querySelector('.wrapper'));
                    }
                }
            })
        })
    }

    let alertButtons = document.querySelectorAll('[data-alert]');
    [...alertButtons].forEach((button) => {
        button.addEventListener( 'click', function(e) {
            e.preventDefault();
            var text = button.getAttribute('data-alert');
            MyThomFormResponseAction('', text);
        })
    })
}

// Back button and barba history
if (history.scrollRestoration) {
    history.scrollRestoration = "manual";
}
let scrollPosY = [0];

/* CONTENT 
LOADED */
document.addEventListener("DOMContentLoaded", () => {
    barba.init({
        // We don't want "synced transition"
        // because both content are not visible at the same time
        // and we don't need next content is available to start the page transition
        // sync: true,
        // requestError: (trigger, action, url, response) => {
        //     console.log(response)
        // },
        // logLevel: 'debug',
        // debug: true,
        timeout: 9000,
        prefetchIgnore: true,
        prevent: ({ el }) => el.hasAttribute("data-alert"),
        transitions: [{
          // NB: `data` was not used.
          // But usually, it's safer (and more efficient)
          // to pass the right container as a paramater to the function
          // and get DOM elements directly from it
          async leave(data) {
            // console.log('transitions leave')
            // Not needed with async/await or promises
            // const done = this.async();
            await pageTransitionIn(data);
            // No more needed as we "await" for pageTransition
            // And i we change the transition duration, no need to update the delay…
            // await delay(1000)
    
            // Not needed with async/await or promises
            // done()
    
            // Loading screen is hiding everything, time to remove old content!
            data.current.container.remove()
          },
    
          async enter(data) {
            // console.log('transitions enter')
            await pageTransitionOut(data.next.container, data, data.next.namespace);
            
          },
          // Variations for didactical purpose…
          // Better browser support than async/await
          // enter({ next }) {
          //   return pageTransitionOut(next.container);
          // },
          // More concise way
          // enter: ({ next }) => pageTransitionOut(next.container),
    
          async once(data) {
            // console.log('transitions once')
            // Navbar
            // console.log(data.next.container)
            await pageTransitionIn(data)
            await pageTransitionOut(document, data, data.next.namespace);

            await navbar(document);
            // await contentAnimation(data.next.container);
          },

          async afterLeave(data) {
            // Kill scrolltrigger instande
            let tlChildren = gsap.globalTimeline.getChildren();
            if(tlChildren){
                tlChildren.forEach(child => {
                    child.kill();
                    child = null;
                });
            }

            // Inject scripts on page
            const js = data.next.container.querySelectorAll("script");
			[].forEach.call(js, function (script) {
				// console.log(script);
				window.eval(script.innerHTML);
			});

            // Menu Change
            document.body.classList.remove('navbar--opened');
            enableBodyScroll(document);
            var newBody = data.next.html;
            var parser = new DOMParser();
            var doc = parser.parseFromString(newBody, "text/html");
            var newMenu = doc.querySelector(".navbar .nav").innerHTML;
            document.querySelector(".navbar .nav").innerHTML = newMenu;            
          }
        }]
    });    
});

let pageTransition = document.querySelector('.pageTransition');
let pageTransitionBlu = pageTransition.querySelector('.blu-screen');
// let pageTransitionWhite = pageTransition.querySelector('.white-screen');
// let mainHeader = document.querySelector('.header');
// let pageTransitionLoader = pageTransition.querySelector('.pageTransitionLoader');
let pageTransitionLoaderLogo = pageTransition.querySelector('.pageTransitionLoaderLogo');

function pageTransitionIn(data) {
    // console.log('page Transition In');
    // console.log(data);
    // GSAP methods can be chained and return directly a promise
    // but here, a simple tween is enough
    return gsap
        .timeline()
        .add('start') // Use a label to sync screen and content animation
        .set(pageTransition, {
            yPercent: 0,
            opacity: 1
        }, 'start')
        .to(pageTransitionBlu, {
            duration: 0.35,
            opacity: 1,
            transformOrigin: 'top left',
            ease: 'power2.out',
            onStart: () => {
                pageTransitionLoaderLogo.classList.add('active');
            }
        }, 'start')
        // .to(pageTransitionWhite, {
        //     duration: 0.8,
        //     yPercent: 0,
        //     transformOrigin: 'top left',
        //     ease: 'power3.out',
        // }, 0.3, 'start')
        // .to(pageTransitionLoader, {
        //     duration: 0.35,
        //     opacity: 1,
        //     ease: 'power1'
        // }, .1, 'start')
}
// Function to add and remove the page transition screen
function pageTransitionOut(container, data, namespace) {
    // console.log('page Transition Out')

    if(data && data.trigger !== "back") {
        scrollPosY.push(barba.history.current.scroll.y);
    }

    if(data.trigger !== "back") {
        window.scrollTo(0, 0, "instant");
      } else {
        window.scrollTo(0, scrollPosY.pop())
    }

    if(timelineInterval){
        clearInterval(timelineInterval);
    }
    
    // GSAP methods can be chained and return directly a promise
    return contentAnimation(container, data, namespace)
}
// Function to animate the content of each page
function contentAnimation(container, data, namespace) {
    (async () => {
        // console.log('content Animation')

        let startMyApp = await App(container, data);
        let hideLoading = await pageTransitionLoadingHide(data);
        return {
            startMyApp,
            hideLoading
         };
    })()    
}
// Hide page transition Loading
function pageTransitionLoadingHide(data) {
    // console.log(data)
    // pageTransition.classList.remove('show');
    
    gsap
        .timeline()
        .add('end') // Use a label to sync screen and content animation
        /*
        .to(pageTransitionLoader, {
            duration: 0.4,
            opacity: 0,
            ease: 'power1'
        }, 'end')
        */
        .to(pageTransitionBlu, {
            duration: 0.4,
            opacity: 0,
            // transformOrigin: 'top left',
            ease: 'power2.out',
            onStart: () => {

                if(data.trigger !== "back") {
                    setTimeout(function(){
                        window.scrollTo(0, 0, "instant")
                    }, 10);
                }
            },
            onComplete: () => {
                // console.log('onComplete')
                // gsap.set(pageTransitionLoader, {
                //     opacity: 0
                // })
                pageTransitionLoaderLogo.classList.remove('active')

                // gsap.set(pageTransitionWhite, {
                //     yPercent: -100
                // })
                // gsap.set(pageTransitionBlu, {
                //     opacity: 0
                // })
                gsap.set(pageTransition, {
                    yPercent: -100,
                    opacity: 0,
                    pointerEvents: "none"
                }, 'end')
            }
        }, .1, 'end')
        // .to(pageTransitionWhite, {
        //     duration: 0.8,
        //     yPercent: 100,
        //     transformOrigin: 'top left',
        //     ease: 'power3.out',
            
        // }, .1, 'end')
}
