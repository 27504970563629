/**
 * Navbar
 */

//  import PerfectScrollbar from 'perfect-scrollbar';
//  import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { gsap } from "gsap";
import { isTouchDevice } from '../../scripts/components/utils';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const hoverIntent = (el, onOver, onOut) => {
  let x;
  let y;
  let pX;
  let pY;
  const h = {};
  let state = 0;
  let timer = 0;

  let options = {
      sensitivity: 7,
      interval: 100,
      timeout: 0,
      overClass: 'hovered'
  };

  const delay = e => {
      if (timer) timer = clearTimeout(timer);
      state = 0;
      if (onOut) {
          return onOut.call(el, e);
      }
      el.classList.remove(options.overClass);
      return false;
  };

  const tracker = e => {
      x = e.clientX;
      y = e.clientY;
  };

  const compare = e => {
      if (timer) timer = clearTimeout(timer);
      if (Math.abs(pX - x) + Math.abs(pY - y) < options.sensitivity) {
          state = 1;
          if (onOver) {
              return onOver.call(el, e);
          }
          el.classList.add(options.overClass);
          return false;
      }
      pX = x;
      pY = y;
      timer = setTimeout(() => {
          compare(e);
      }, options.interval);
      return false;
  };

  // Public methods

  const dispatchOver = e => {
      if (timer) timer = clearTimeout(timer);
      el.removeEventListener('mousemove', tracker, false);

      if (state !== 1) {
          pX = e.clientX;
          pY = e.clientY;

          el.addEventListener('mousemove', tracker, false);

          timer = setTimeout(() => {
              compare(e);
          }, options.interval);
      }

      return this;
  };

  const dispatchOut = e => {
      if (timer) timer = clearTimeout(timer);
      el.removeEventListener('mousemove', tracker, false);

      if (state === 1) {
          timer = setTimeout(() => {
              delay(e);
          }, options.timeout);
      }

      return this;
  };

  if (el) {
      el.addEventListener('mouseover', dispatchOver, false);
      el.addEventListener('mouseout', dispatchOut, false);
  }

  h.options = opt => {
      options = { ...options, ...opt };
      return h;
  };

  h.remove = () => {
      if (!el) return;
      el.removeEventListener('mouseover', dispatchOver, false);
      el.removeEventListener('mouseout', dispatchOut, false);
  };

  return h;
};

// let header = '';
// let targetElement = '';
// let navbarToggler = '';
// let pinOffset = 0;
// let prevScrollPos;

const navbar = (container) => {
  let header = container.querySelector('.header');
  let targetElement = container.querySelector('.js-navbar');
  let navbarToggler = targetElement.querySelectorAll('.js-navbar-toggler');
  let navbarCloseBtn = targetElement.querySelector('.nav--close');

  let open = () => {
    document.body.classList.add('navbar--opened');
    disableBodyScroll(document);
  }

  let close = () => {
    document.body.classList.remove('navbar--opened');
    enableBodyScroll(document)
  }

  // Menù Button to open Menu
  if (navbarToggler) {
    close();

    navbarToggler.forEach(function(item) {
      
      item.addEventListener('click', function (event) {
        event.preventDefault();      
        if(document.body.classList.contains('navbar--opened')){
          close();
        } else {
          open();
        }
      });
    });    
  }

  if(navbarCloseBtn) {
    navbarCloseBtn.addEventListener('click', function (event) {
      event.preventDefault();
      close();
    });
  }
}

export {navbar};
